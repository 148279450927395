<template>
  <div
    class="base-loader"
    :class="{'backdrop': hasBg}"
  >
    <div
      class="base-loader__wrap"
      :class="position ? `pos-${position}` : ''"
    >
      <dot-loader :color="dotColor" />
    </div>
  </div>
</template>

<script>
import DotLoader from '@/components/ui/Loaders/DotLoader';

export default {
  components: {
    DotLoader,
  },
  props: {
    dotColor: {
      type: String,
      default: '#574D92',
    },
    hasBg: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .2);
  position: absolute;
  z-index: 9;

  .base-loader__wrap {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    height: 80px;
  }
}

.pos-absolute {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.pos-fixed {
  position: fixed;
  top: 0;
  right: 0;
}
</style>
