<template>
  <div class="empty-wrap">
    <slot />
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.empty-wrap {
  text-align: center;
  text-transform: uppercase;
  font-size: $type-xl;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
