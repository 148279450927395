<template>
  <dot-loader
    v-if="getPageLoading"
    :blue="true"
    style="top: 50%"
  />
  <section-wrap v-else>
    <template #title>
      <div class="d-flex">
        <h1 class="section-title mr-5">
          Notifications
        </h1>
      </div>
    </template>
    <section-container class="notifications-container" :header="false">
      <template #body>
        <div
          v-if="getNotificationsInfo.data.length > 0"
          style="height: inherit"
        >
          <v-row class="justify-center mb-6">
            <v-col md="6" class="d-flex flex-column">
              <div class="d-flex justify-space-between align-center mb-6">
                <h2>
                  {{ getNotificationsInfo.meta.total }} new notifications
                </h2>
                <text-button color="primary" @click="readAllNotifications">
                  Mark All as Read
                </text-button>
              </div>
              <v-divider />
            </v-col>
          </v-row>
          <notification-list @load-more="loadMore" />
        </div>
        <empty-no-data v-else>
          You currently do not have any notifications
        </empty-no-data>
      </template>
    </section-container>
  </section-wrap>
</template>

<script>
// TODO refactor Section Wrap && Section Container height - remove vh use only %
import SectionWrap from '@/components/collections/SectionWrap/SectionWrap';
import SectionContainer from '@/components/collections/SectionContainer/SectionContainer';
import TextButton from '@/components/ui/Buttons/TextButton';
import DotLoader from '@/components/ui/Loaders/DotLoader';
import EmptyNoData from '@/components/ui/Empty/EmptyNoData';
import NotificationList from '@/components/collections/NotificationList/NotificationList';
import { mapGetters } from 'vuex';

export default {
  name: 'Notification',
  components: {
    SectionContainer,
    SectionWrap,
    TextButton,
    DotLoader,
    EmptyNoData,
    NotificationList,
  },
  data() {
    return {
      check: [],
    };
  },
  computed: {
    ...mapGetters({
      getPageLoading: 'getPageLoading',
      getNotificationsInfo: 'getNotificationsInfo',
    }),
  },
  mounted() {
    this.fetchNotificationsList();
  },
  beforeDestroy() {
    this.$store.commit('CLEAR_STATE');
  },
  methods: {
    fetchNotificationsList(page) {
      this.$store.dispatch('GET_NOTIFICATIONS_LIST', page);
    },
    readAllNotifications() {
      this.$store.dispatch('READ_ALL_NOTIFICATIONS');
    },
    loadMore() {
      const { meta } = this.getNotificationsInfo;
      this.fetchNotificationsList(meta.current_page + 1);
    },
  },
};
</script>

<style scoped lang="scss">
.notifications-container {
  height: calc(100vh - 183px);
  position: relative;
  overflow: hidden;

  ::v-deep.section-content-body {
    padding: 24px 0 40px 0;
  }
}
</style>
