<template>
  <v-col class="pa-0" style="position: relative">
    <base-loader v-if="getLoading" />
    <v-list ref="scrollContainer" class="custom-v__list">
      <notification-item
        v-for="notification in getNotificationsInfo.data"
        :key="notification.id"
        :notification="notification"
      />
      <div ref="sentinel" />
    </v-list>
  </v-col>
</template>

<script>
import NotificationItem from '@/components/collections/NotificationItem/NotificationItem';
import BaseLoader from '@/components/ui/Loaders/BaseLoader';
import { mapGetters } from 'vuex';

export default {
  name: 'NotificationList',
  components: { NotificationItem, BaseLoader },

  data() {
    return {
      timer: null,
    };
  },

  computed: {
    ...mapGetters({
      getLoading: 'getLoading',
      getNotificationsInfo: 'getNotificationsInfo',
    }),
  },

  mounted() {
    this.$nextTick(() => {
      this.setUpInterSectionObserver();
    });
  },

  beforeDestroy() {
    clearTimeout(this.timer);
  },

  methods: {
    setUpInterSectionObserver() {
      this.readNotificationsBySetTimeout();

      const options = {
        root: this.$refs.scrollContainer.$el,
        margin: '10px',
      };
      this.listEndObserver = new IntersectionObserver(
        this.handleIntersection,
        options,
      );

      this.listEndObserver.observe(this.$refs.sentinel);
    },
    handleIntersection([entry]) {
      const { meta } = this.getNotificationsInfo;

      if (entry.isIntersecting && meta.current_page !== meta.last_page) {
        this.readNotificationsBySetTimeout();
        this.$emit('load-more');
      }
    },
    readNotificationsBySetTimeout() {
      this.timer = setTimeout(() => {
        this.$store.dispatch('READ_CURRENT_NOTIFICATIONS',
          this.getNotificationsInfo.ids);
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .custom-v__list {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    height: calc(100vh - 316px);
    border-top: 1px solid $grey-lightest;
  }

</style>
