<template>
  <v-list-item class="custom-list__item">
    <v-list-item-avatar rounded>
      <v-img
        :src="notification.from_user.image"
        :alt="notification.from_user.name"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-text="notification.from_user.name" />
      <v-list-item-subtitle
        style="font-size: 1rem"
        v-html="notification.message"
      />
    </v-list-item-content>
    <v-list-item-action class="align-self-center">
      <badge
        :dot="true"
        :offset-y="-18"
        :offset-x="10"
        :color="notification.readed ? '#ccc' : 'primary'"
      />
      <v-list-item-title v-text="notification.created_day + ' ' + notification.created_time" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import Badge from '@/components/ui/Badge/Badge';

export default {
  components: {
    Badge,
  },
  props: {
    notification: {
      type: Object,
      default: (() => {}),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.custom-list__item {
  border: 1px solid $grey-lightest;
  box-shadow: 0 0 7px 0 $grey-lightest;
  min-height: 85px;
  max-height: 86px;

  &:first-of-type {
    border-top: none;
  }

  ::v-deep.v-badge__badge {
    width: 10px;
    height: 10px;
  }
}
</style>
